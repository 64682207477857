import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import SweetScroll from 'sweet-scroll';

import 'slim-select/dist/slimselect.min.css'

import Alpine from 'alpinejs'
import 'tailwindcss/tailwind.css'

import "@fontsource/lato/300.css"
import "@fontsource/lato/400.css"
import "@fontsource/lato/700.css"
import "@fontsource/lato/900.css"

import 'controllers'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()
Alpine.start()

document.addEventListener('turbolinks:load', () => {
  const sweetScroll = new SweetScroll({ /* some options */ });
}, false);