import { Controller } from 'stimulus'



export default class extends Controller {
  static targets = []

  connect() {
    new SlimSelect({
      select: '#multiple'
    })
  }
}
